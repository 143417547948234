import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module-loader" }

import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'


export default /*@__PURE__*/_defineComponent({
  __name: 'NewsletterModal',
  setup(__props) {

const newsletter = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "freeplay" */ '@gd/newsletter'),
  loadingComponent: AsyncProgress,
})
const newsletterStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "freeplay" */ './NewsletterModalStyles.vue'),
})

const show = ref(false)

events.$on('notifications', (data: Array<any>) => {
  if (data.length > 0) {
    data.forEach((item: any) => {
      if ((item.type as GD_notifications.NewsletterModalType) === 'newsletterModal') {
        show.value = true
      }
    })
  }
})

const onClose = () => {
  fetch('/gd/rest/me/notifications/newsletterModal', {
    method: 'delete',
    credentials: 'same-origin',
    cache: 'no-cache',
  }).catch((err) => {
    console.error(err)
  })
  show.value = false
}

const onNewsletterClose = () => {
  show.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_gd_modal = _resolveComponent("gd-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (show.value)
      ? (_openBlock(), _createBlock(_component_gd_modal, {
          key: 0,
          onCloseBG: onClose,
          onCloseESC: onClose,
          onClose: onClose
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(newsletter), {
              size: "s",
              onClose: onNewsletterClose
            }),
            _createVNode(_unref(newsletterStyles))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})