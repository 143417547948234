import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module-loader" }

import { Ref, defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'

interface Comparison {
  gameName: string
  gametype: string
  opponentId: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserComparisonModal',
  setup(__props) {

const UserComparison = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "user-comparison" */ '@gd/user-comparison'),
  loadingComponent: AsyncProgress,
})
const UserComparisonStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "user-comparison" */ './UserComparisonStyles.vue'),
})

const show = ref(false)
const configRef: Ref<Comparison> = ref({
  gameName: '',
  gametype: '',
  opponentId: '',
})

events.$on('user-comparison', (config: { gameName: string; gametype: string; opponentId: string }) => {
  configRef.value = config
  show.value = true
})

events.$on('close-modals', () => {
  show.value = false
})

const onClose = () => {
  show.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_modal_content = _resolveComponent("modal-content")!
  const _component_modal_layout = _resolveComponent("modal-layout")!
  const _component_modal_app = _resolveComponent("modal-app")!
  const _component_gd_modal = _resolveComponent("gd-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (show.value)
      ? (_openBlock(), _createBlock(_component_gd_modal, {
          key: 0,
          onCloseBG: onClose,
          onCloseESC: onClose,
          onClose: onClose,
          m: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_modal_app, { m: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_modal_layout, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_modal_content, { class: "user-comparison-wrapper" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(UserComparison), {
                          class: "user-comparison",
                          gametype: configRef.value.gametype,
                          "game-name": configRef.value.gameName,
                          "opponent-id": configRef.value.opponentId
                        }, null, 8, ["gametype", "game-name", "opponent-id"]),
                        _createVNode(_unref(UserComparisonStyles))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})