<template>
  <div class="module-loader">
    <gd-modal v-if="show" @closeBG="onClose" @closeESC="onClose" @close="onClose">
      <special-offer-promotion class="special-offer" @onClose="onClose" @onError="onError" />
      <special-offer-promotion-styles />
    </gd-modal>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'

const SpecialOfferPromotion = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "special-offer-promotion" */ '@gd/special-offer-promotion'),
  loadingComponent: AsyncProgress,
})
const SpecialOfferPromotionStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "special-offer-promotion" */ './SpecialOfferStyles.vue'),
})

const show = ref(false)

events.$on('notifications', (data: Array<any>) => {
  if (data.length > 0) {
    data.forEach((item: any) => {
      if (item.type === 'specialOffer') {
        show.value = true
      }
    })
  }
})

const onError = () => {
  // for the first we ignore errors
  show.value = false
}

const onClose = () => {
  show.value = false
}
</script>

<style lang="less" scoped>
@import '~@npm/@gd/modal/dist/modal.css';
.module-loader&::v-deep .gd-modal-component-body,
.special-offer {
  min-width: 18em;
  min-height: 15em;
}
</style>
