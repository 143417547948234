import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module-loader" }

import { GD, Storage, STORE_NAME } from '@gd/storage'
import events from '@gd/event-bus'
import AsyncProgress from './AsyncProgress.vue'
import { defineAsyncComponent, getCurrentInstance, Ref, ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ShopExpress',
  setup(__props) {

const app = getCurrentInstance()?.appContext.app

/**
 * ShopExpress needs to register the store (vuex) with the module-loader app
 */
const ShopExpress = defineAsyncComponent({
  loader: () => {
    return new Promise(async (resolve, reject) => {
      const { registerComponent } = await import(/* webpackChunkName: "shop-express" */ '@gd/shop-express' as any)
      try {
        const component = registerComponent(app)
        resolve(component)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  },
  loadingComponent: AsyncProgress,
})

const ShopExpressStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "shop-express" */ './ShopExpressStyles.vue'),
})

const depositSuccess = ref(false)
const blocked = ref(false)
const showExpress = ref(false)

const abortCallback: Ref<Function | undefined> = ref(undefined)

events.$on('shop-express', () => {
  showExpress.value = true
})

events.$on('close-modals', () => {
  showExpress.value = false
})

const onDepositStart = (abort: Function) => {
  abortCallback.value = abort
  blocked.value = abortCallback.value === undefined
}

const onDepositSuccess = () => {
  depositSuccess.value = true
  blocked.value = false
}

const onError = (obj: any) => {
  blocked.value = false
}

const onErrorButton = (errorCode: string) => {
  switch (errorCode) {
    case 'no-expresspayment-provider':
    case 'no-purchasables':
    case 'no_purchasables_for_express_payment':
    case 'unavailable':
    case '50':
      onShop()
      break
    default:
      showExpress.value = false
  }
}

const onSettings = () => {
  location.href = '/gd/myGameDuell/shopSettings.xhtml'
}

const onShop = () => {
  const storage: GD = new Storage().read(STORE_NAME.GD)
  location.href = `${storage.webCtxPath}/shop-web/index.html`
}

const onSuccess = () => {
  showExpress.value = false
  events.$emit('update-balance')
}

const onClose = () => {
  if (abortCallback.value) {
    abortCallback.value()
  }
  if (depositSuccess.value) {
    onSuccess()
  }
  showExpress.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_gd_modal = _resolveComponent("gd-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (showExpress.value)
      ? (_openBlock(), _createBlock(_component_gd_modal, {
          key: 0,
          blocked: blocked.value,
          onCloseBG: onClose,
          onCloseESC: onClose,
          onClose: onClose
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(ShopExpress), {
              class: "classic-shop-express",
              onOnCompleteButton: onSuccess,
              onOnErrorButton: onErrorButton,
              onOnSettings: onSettings,
              onOnShop: onShop,
              onOnDepositStart: onDepositStart,
              onOnDepositSuccess: onDepositSuccess,
              onOnError: onError,
              onOnClose: onClose
            }),
            _createVNode(_unref(ShopExpressStyles))
          ]),
          _: 1
        }, 8, ["blocked"]))
      : _createCommentVNode("", true)
  ]))
}
}

})