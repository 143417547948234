import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module-loader" }

import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'


export default /*@__PURE__*/_defineComponent({
  __name: 'LoyaltyTransitionModal',
  setup(__props) {

const LoyaltyTransition = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "loyalty-transition" */ '@gd/loyalty-transition'),
  loadingComponent: AsyncProgress,
})
const LoyaltyTransitionStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "loyalty-transition" */ './LoyaltyTransitionModalStyles.vue'),
})

const show = ref(false)

events.$on('notifications', (data: Array<any>) => {
  if (data.length > 0) {
    data.forEach((item: any) => {
      if (item.type === 'loyalty') {
        show.value = true
      }
    })
  }
})

const onError = () => {
  // for the first we ignore errors
}

const onClose = () => {
  show.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_gd_modal = _resolveComponent("gd-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (show.value)
      ? (_openBlock(), _createBlock(_component_gd_modal, {
          key: 0,
          onCloseBG: onClose,
          onCloseESC: onClose,
          onClose: onClose
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(LoyaltyTransition), {
              class: "loyalty-transition",
              onClose: onClose,
              onError: onError
            }),
            _createVNode(_unref(LoyaltyTransitionStyles))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})