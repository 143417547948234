import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module-loader" }

import { Preselections } from '@gd/customer-service-form/types/src/model/Preselections'
import AsyncProgress from './AsyncProgress.vue'
import { Ref, defineAsyncComponent, ref } from 'vue'
import events from '@gd/event-bus'


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerServiceForm',
  setup(__props) {

const CustomerServiceForm = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "cs-form" */ '@gd/customer-service-form'),
  loadingComponent: AsyncProgress,
})
const CustomerServiceFormStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "cs-form" */ './CustomerServiceFormStyles.vue'),
})

const show = ref(false)
const preselectionData: Ref<Preselections | undefined> = ref(undefined)

const modalSize: Ref<'s' | 'm' | 'l'> = ref('l')

// Implement in cs-form component
// Ask Szymon if size S is okay?
const onSubmit = () => {
  modalSize.value = 's'
}

events.$on('cs-form', (data: Preselections) => {
  preselectionData.value = { ...data }
  show.value = true
})

events.$on('close-modals', () => {
  show.value = false
})

const onClose = () => {
  show.value = false
  modalSize.value = 'l'
}

const onError = () => {
  onClose()
}

return (_ctx: any,_cache: any) => {
  const _component_modal_app = _resolveComponent("modal-app")!
  const _component_gd_modal = _resolveComponent("gd-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (show.value)
      ? (_openBlock(), _createBlock(_component_gd_modal, {
          key: 0,
          onCloseBG: onClose,
          onCloseESC: onClose,
          onClose: onClose
        }, {
          default: _withCtx(() => [
            _createVNode(_component_modal_app, { size: modalSize.value }, {
              default: _withCtx(() => [
                _createVNode(_unref(CustomerServiceForm), {
                  class: "customer-service-app",
                  preselections: preselectionData.value,
                  onCloseFailed: onError,
                  onClose: onClose,
                  onSubmitted: onSubmit
                }, null, 8, ["preselections"]),
                _createVNode(_unref(CustomerServiceFormStyles))
              ]),
              _: 1
            }, 8, ["size"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})