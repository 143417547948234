import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module-loader" }

import { Ref, defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpshiftArticle',
  setup(__props) {

const HelpshiftArticle = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "helpshift-article" */ '@gd/helpshift-article' as any),
  loadingComponent: AsyncProgress,
})
const HelpshiftArticleStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "helpshift-article" */ './HelpshiftArticleStyles.vue'),
})

const disableLinksRef = ref(true)
const show = ref(false)
const nameRef: Ref<string | undefined> = ref(undefined)

events.$on('helpshift-article', ({ name, disableLinks }: { name: string; disableLinks: boolean }) => {
  nameRef.value = name
  if (disableLinks !== undefined) {
    disableLinksRef.value = disableLinks
  }
  show.value = true
})

events.$on('close-modals', () => {
  show.value = false
})

const onClose = () => {
  show.value = false
}

const onError = () => {
  onClose()
}

return (_ctx: any,_cache: any) => {
  const _component_modal_app = _resolveComponent("modal-app")!
  const _component_gd_modal = _resolveComponent("gd-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (show.value)
      ? (_openBlock(), _createBlock(_component_gd_modal, {
          key: 0,
          onCloseBG: onClose,
          onCloseESC: onClose,
          onClose: onClose
        }, {
          default: _withCtx(() => [
            _createVNode(_component_modal_app, { m: "" }, {
              default: _withCtx(() => [
                _createVNode(_unref(HelpshiftArticle), {
                  class: "helpshift-article",
                  article: nameRef.value,
                  "links-disabled": disableLinksRef.value,
                  onCloseFailed: onError,
                  onClose: onClose
                }, null, 8, ["article", "links-disabled"]),
                _createVNode(_unref(HelpshiftArticleStyles))
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})