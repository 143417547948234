import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module-loader" }

import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'
import { jslog } from '@gd/jslog'


export default /*@__PURE__*/_defineComponent({
  __name: 'BonusInfusion',
  setup(__props) {

const BonusInfusion = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "bonus-infusion" */ '@gd/bonus-infusion'),
  loadingComponent: AsyncProgress,
})
const BonusInfusionStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "bonus-infusion" */ './BonusInfusionStyles.vue')
})

  const show = ref(false)
  const threshold = ref('0')
  const amount = ref('0')

  events.$on('notifications', (data: Array<any>) => {
    if (data.length > 0) {
      data.forEach((item: any) => {
        if (item.type === 'bonusInfusion') {
          show.value = true
        }
      })
    }
  })

  const onSuccess = () => {
    show.value = false
    events.$emit('update-balance')
  }

  const onError = (err: any) => {
    // for the first we ignore errors
    jslog.send({ name: 'bonus-infusion-modal', error: err })
    show.value = false
  }

  const onClose = () => {
    fetch('/gd/rest/me/notifications/bonusInfusion', {
      method: 'DELETE',
      credentials: 'same-origin',
      cache: 'no-cache'
    }).catch(err => {
      console.error(err)
    })
    show.value = false
  }

return (_ctx: any,_cache: any) => {
  const _component_gd_modal = _resolveComponent("gd-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (show.value)
      ? (_openBlock(), _createBlock(_component_gd_modal, {
          key: 0,
          onCloseESC: onClose,
          onClose: onClose
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(BonusInfusion), {
              class: "bonus-infusion",
              onClose: onSuccess,
              onError: onError,
              size: "s"
            }),
            _createVNode(_unref(BonusInfusionStyles))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})