import { createApp } from 'vue'
import i18n from '@gd/i18n'
import ModuleLoader from './components/ModuleLoader.vue'
import { GD, Storage, STORE_NAME } from '@gd/storage'
import { loadTComponents } from '@gd/t-components'
import { loadModalComponents } from '@gd/modal'
import GdModal from '@gd/modal'
import toast from '@gd/toast'
import '@gd/toast/dist/toast.css'
import '@gd/common-styles/dist/fonts/dSari-loadFonts.less'
import '@gd/common-styles/dist/icf-fonts.less'
import '@gd/modal/dist/modal.css'
import '@gd/t-components/dist/t-components.css'

const storageGd: GD = new Storage().read(STORE_NAME.GD)
// setup dynamically web context path for production - otherwise the shop-express will be not loaded from stage
if (process.env.NODE_ENV === 'production') {
  __webpack_public_path__ = `${storageGd.webCtxPath}/module-loader-weblib/`
}

// TODO remove class check - it's only to avoid double initialisation as long as the classic-web contains the module-loader
// Attention: it will also not initialize when a class named module-loader exist somewhere else !!!!
if (document.querySelector('.module-loader')) {
  console.error("module-loader can't be registered twice in DOM")
} else {
  const vueContainer = document.body.appendChild(document.createElement('div'))
  vueContainer.setAttribute('id', 'vue-module-loader-app')
  const app = createApp(ModuleLoader).use(i18n).use(toast, { global: true })
  loadTComponents(app)
  loadModalComponents(app)
  app.component('gd-modal', GdModal)
  app.mount(vueContainer)
}
