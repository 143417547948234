import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module-loader" }

import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangePasswordModal',
  setup(__props) {

const ChangePassword = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "change-password" */ '@gd/change-password'),
  loadingComponent: AsyncProgress,
})
const ChangePasswordStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "change-password" */ './ChangePasswordStyles.vue'),
})

const show = ref(false)

events.$on('notifications', (data: Array<any>) => {
  if (data.length > 0) {
    data.forEach((item: any) => {
      if (item.type === 'changePassword') {
        show.value = true
      }
    })
  }
})

const onSuccess = () => {
  show.value = false
  fetch('/gd/rest/me/notifications/ack/changePassword', {
    method: 'POST',
    credentials: 'same-origin',
    cache: 'no-cache',
  })
    .then(() => {
      location.href = '/gd/playerProfile/changePassword.xhtml'
    })
    .catch((err) => {
      console.error(err)
    })
}

const onClose = () => {
  fetch('/gd/rest/me/notifications/changePassword', {
    method: 'DELETE',
    credentials: 'same-origin',
    cache: 'no-cache',
  }).catch((err) => {
    console.error(err)
  })
  show.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_gd_modal = _resolveComponent("gd-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (show.value)
      ? (_openBlock(), _createBlock(_component_gd_modal, {
          key: 0,
          onCloseESC: onClose,
          onCloseBG: onClose,
          onClose: onClose
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(ChangePassword), {
              class: "change-password",
              onChange: onSuccess,
              onClose: onClose,
              size: "s"
            }),
            _createVNode(_unref(ChangePasswordStyles))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})