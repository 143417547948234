import { defineComponent as $, computed as w, openBlock as c, createElementBlock as r, normalizeClass as x, createElementVNode as t, renderSlot as a, ref as u, onMounted as E, onUnmounted as M, createBlock as z, Transition as B, withCtx as L, unref as T, createVNode as q, createCommentVNode as I, normalizeStyle as N } from "vue";
const S = { class: "modal-app-wrap" }, W = /* @__PURE__ */ $({
  __name: "ModalApp",
  props: ["xs", "s", "m", "l", "xl", "size"],
  setup(e) {
    const o = e, n = w(() => {
      const s = [o.size ? "modal-prop--" + o.size.toLowerCase() : ""];
      return Object.keys(o).forEach((d) => {
        o[d] !== void 0 && s.push("modal-prop--" + d);
      }), s;
    });
    return (s, d) => (c(), r("div", {
      class: x(["modal-app", n.value])
    }, [
      t("div", S, [
        a(s.$slots, "default")
      ])
    ], 2));
  }
}), i = (e, o) => {
  const n = e.__vccOpts || e;
  for (const [s, d] of o)
    n[s] = d;
  return n;
}, G = {}, H = { class: "modal-layout" };
function V(e, o) {
  return c(), r("div", H, [
    a(e.$slots, "default")
  ]);
}
const A = /* @__PURE__ */ i(G, [["render", V]]), D = {}, O = { class: "modal-content" };
function j(e, o) {
  return c(), r("div", O, [
    a(e.$slots, "default")
  ]);
}
const R = /* @__PURE__ */ i(D, [["render", j]]), U = {}, F = { class: "modal-actions" };
function J(e, o) {
  return c(), r("div", F, [
    a(e.$slots, "default")
  ]);
}
const K = /* @__PURE__ */ i(U, [["render", J]]), P = {}, Q = { class: "modal-title" };
function X(e, o) {
  return c(), r("div", Q, [
    t("span", null, [
      a(e.$slots, "default")
    ])
  ]);
}
const Y = /* @__PURE__ */ i(P, [["render", X]]), Z = {}, ee = { class: "modal-disclaimer base ts0" };
function oe(e, o) {
  return c(), r("div", ee, [
    a(e.$slots, "default")
  ]);
}
const te = /* @__PURE__ */ i(Z, [["render", oe]]), ne = {}, se = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 30 30",
  "xml:space": "preserve"
};
function le(e, o) {
  return c(), r("svg", se, o[0] || (o[0] = [
    t("g", null, [
      t("circle", {
        cx: "15",
        cy: "15",
        r: "15.094",
        style: { fill: "rgb(255, 255, 255)" }
      }),
      t("circle", {
        class: "circle2",
        cx: "15",
        cy: "15",
        r: "10.166"
      })
    ], -1),
    t("g", {
      transform: "translate(8,8) scale(0.7)",
      style: { fill: "rgb(255, 255, 255)", "fill-rule": "evenodd", "clip-rule": "evenodd" }
    }, [
      t("g", null, [
        t("path", {
          class: "st1",
          d: `M12.706,10l4.736-4.736c0.744-0.744,0.744-1.962,0-2.706c-0.744-0.744-1.962-0.744-2.706,0L10,7.294
		L5.264,2.558c-0.744-0.744-1.962-0.744-2.706,0c-0.744,0.744-0.744,1.962,0,2.706L7.294,10l-4.736,4.736
		c-0.744,0.744-0.744,1.962,0,2.706c0.744,0.744,1.962,0.744,2.706,0L10,12.706l4.736,4.736c0.744,0.744,1.962,0.744,2.706,0
		c0.744-0.744,0.744-1.962,0-2.706L12.706,10z`
        })
      ])
    ], -1)
  ]));
}
const ce = /* @__PURE__ */ i(ne, [["render", le]]);
function C() {
  const e = {
    w: document.documentElement && document.documentElement.clientWidth || window.innerWidth,
    h: document.documentElement && document.documentElement.clientHeight || window.innerHeight
  }, o = document.createElement("div");
  return document.body.appendChild(o), document.body.removeChild(o), {
    w: e.w,
    h: e.h
  };
}
const de = { class: "gd-modal-component-container" }, ae = ["data-qe-id"], ie = /* @__PURE__ */ $({
  __name: "GdModal",
  props: {
    qeIdCloseBtn: { default: "gd-modal--close-button" },
    blocked: { type: Boolean, default: !1 },
    onClose: {}
  },
  emits: ["closeESC", "close", "closeBG"],
  setup(e, { emit: o }) {
    const n = e, s = o, d = u(null), v = u(null);
    let h = u(!0), f = u(C().h), _;
    E(() => {
      console.log("onMounted", n.onClose), n.onClose || (h.value = !1), document.addEventListener("keydown", (l) => {
        n.blocked || l.keyCode == 27 && s("closeESC");
      }), window.addEventListener("resize", k);
    }), M(() => {
      window.removeEventListener("resize", k);
    });
    const y = w(() => f.value - (f.value < 300 ? 50 : 100) + "px");
    function b() {
      n.blocked || s("close");
    }
    function g(l) {
      (!n.blocked && d.value === l.target || v.value === l.target) && s("closeBG");
    }
    function k() {
      clearTimeout(_), _ = setTimeout(() => {
        f.value = C().h, clearTimeout(_);
      }, 500);
    }
    return (l, m) => (c(), z(B, { name: "gd-modal-component" }, {
      default: L(() => [
        t("div", {
          ref_key: "backgroundClick",
          ref: d,
          class: "gd-modal-component-mask",
          onClick: m[1] || (m[1] = (p) => g(p))
        }, [
          t("div", {
            ref_key: "backgroundClickWrapper",
            ref: v,
            class: "gd-modal-component-wrapper",
            onClick: m[0] || (m[0] = (p) => g(p))
          }, [
            t("div", de, [
              !l.blocked && T(h) ? (c(), r("div", {
                key: 0,
                "data-qe-id": l.qeIdCloseBtn,
                class: "gd-modal-component-close",
                onClick: b
              }, [
                q(ce)
              ], 8, ae)) : I("", !0),
              a(l.$slots, "modal-top"),
              t("div", {
                class: "gd-modal-component-body",
                style: N(`max-height: ${y.value}`)
              }, [
                a(l.$slots, "default")
              ], 4)
            ])
          ], 512)
        ], 512)
      ]),
      _: 3
    }));
  }
});
function me(e) {
  e.component("modal-app", W), e.component("modal-layout", A), e.component("modal-content", R), e.component("modal-actions", K), e.component("modal-title", Y), e.component("modal-disclaimer", te);
}
export {
  ie as default,
  me as loadModalComponents
};
