import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module-loader" }

import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'


export default /*@__PURE__*/_defineComponent({
  __name: 'MinileagueHowtoModal',
  setup(__props) {

const MinileagueHowto = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "minileague-howto" */ '@gd/minileague-howto' as any),
  loadingComponent: AsyncProgress,
})
const MinileagueHowtoStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "minileague-howto" */ './MinileagueHowtoStyles.vue'),
})

const show = ref(false)

events.$on('minileague-howto', () => {
  show.value = true
})

const onClose = () => {
  show.value = false
}

const onError = () => {
  onClose()
}

return (_ctx: any,_cache: any) => {
  const _component_modal_app = _resolveComponent("modal-app")!
  const _component_gd_modal = _resolveComponent("gd-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (show.value)
      ? (_openBlock(), _createBlock(_component_gd_modal, {
          key: 0,
          onCloseBG: onClose,
          onCloseESC: onClose,
          onClose: onClose
        }, {
          default: _withCtx(() => [
            _createVNode(_component_modal_app, { l: "" }, {
              default: _withCtx(() => [
                _createVNode(_unref(MinileagueHowto), {
                  class: "minileague-howto-app",
                  onCloseFailed: onError
                }),
                _createVNode(_unref(MinileagueHowtoStyles))
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})