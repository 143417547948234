import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module-loader" }

import { Ref, defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'
import { jslog } from '@gd/jslog'


export default /*@__PURE__*/_defineComponent({
  __name: 'FreeplayModal',
  setup(__props) {

const freeplay = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "freeplay" */ '@gd/freeplay'),
  loadingComponent: AsyncProgress,
})
const freeplayStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "freeplay" */ './FreeplayModalStyles.vue'),
})

const show = ref(false)
const type: Ref<'userBirthday' | 'gdBirthday' | 'freeplay'> = ref('freeplay')

events.$on('notifications', (data: Array<any>) => {
  if (data.length > 0) {
    data.forEach((item: any) => {
      if (item.type === 'freeplay' || item.type === 'gdBirthday' || item.type === 'userBirthday') {
        type.value = item.type
        show.value = true
      }
    })
  }
})

const closeFreeplay = ({
  reason,
  err,
  type,
}: {
  reason?: string
  err: string
  type?: 'userBirthday' | 'gdBirthday' | 'freeplay'
}) => {
  if (reason) {
    // error hapens, close modal:
    show.value = false
    jslog.send({ name: 'freeplay-modal', error: new Error(err), data: { reason } })
  } else if (type) {
    // will be called when the submit button in modal is clicked (hurray... e.g. for DK users)
    fetch('/gd/rest/me/notifications/' + type, {
      method: 'delete',
      credentials: 'same-origin',
      cache: 'no-cache',
    }).catch((err) => {
      console.error(err)
    })
  }
  // close anyway
  show.value = false
}

const onClose = () => {
  fetch('/gd/rest/me/notifications/' + type.value, {
    method: 'delete',
    credentials: 'same-origin',
    cache: 'no-cache',
  }).catch((err) => {
    console.error(err)
  })
  show.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_gd_modal = _resolveComponent("gd-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (show.value)
      ? (_openBlock(), _createBlock(_component_gd_modal, {
          key: 0,
          onCloseESC: onClose,
          onClose: onClose
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(freeplay), {
              type: type.value,
              onClose: closeFreeplay,
              size: "m"
            }, null, 8, ["type"]),
            _createVNode(_unref(freeplayStyles))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})