import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "vue-module-loader-app",
  class: "module-loader"
}

import ShopExpress from './ShopExpress.vue'
import AddressUpdateModal from './AddressUpdateModal.vue'
import BonusInfusion from './BonusInfusion.vue'
import ChangePasswordModal from './ChangePasswordModal.vue'
import DailyQuestModal from './DailyQuestModal.vue'
import FreeplayModal from './FreeplayModal.vue'
import CustomerServiceForm from './CustomerServiceForm.vue'
import GameTechnologiesCheck from './GameTechnologiesCheck.vue'
import HelpshiftArticle from './HelpshiftArticle.vue'
import LoyaltyTransitionModal from './LoyaltyTransitionModal.vue'
import MinileagueHowtoModal from './MinileagueHowtoModal.vue'
import NewsletterModal from './NewsletterModal.vue'
import SpecialOfferModal from './SpecialOfferModal.vue'
import SpecialOfferTeaser from './SpecialOfferTeaser.vue'
import UnlockAvatar from './UnlockAvatarModal.vue'
import UserComparison from './UserComparisonModal.vue'
import LoyaltyWoodModal from './LoyaltyWoodModal.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'ModuleLoader',
  setup(__props) {

/**
 * Contains all asynchounous loaded modules. If there is extra logic required for the
 * individual module than please put in in a extra file ( see shop-express )
 */


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ShopExpress),
    _createVNode(AddressUpdateModal),
    _createVNode(BonusInfusion),
    _createVNode(ChangePasswordModal),
    _createVNode(DailyQuestModal),
    _createVNode(FreeplayModal),
    _createVNode(CustomerServiceForm),
    _createVNode(GameTechnologiesCheck),
    _createVNode(HelpshiftArticle),
    _createVNode(LoyaltyTransitionModal),
    _createVNode(MinileagueHowtoModal),
    _createVNode(NewsletterModal),
    _createVNode(SpecialOfferModal),
    _createVNode(SpecialOfferTeaser),
    _createVNode(UnlockAvatar),
    _createVNode(UserComparison),
    _createVNode(LoyaltyWoodModal)
  ]))
}
}

})