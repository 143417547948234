import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgressHorizontal.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SpecialOfferTeaser',
  setup(__props) {

const SpecialOfferTeaser = defineAsyncComponent({
  loader: () => {
    return import(/* webpackChunkName: "special-offer-promotion" */ '@gd/special-offer-promotion').then(
      (SpecialOfferPromotion) => {
        return SpecialOfferPromotion.SpecialOfferTeaser
      },
    )
  },
  loadingComponent: AsyncProgress,
})
const SpecialOfferPromotionStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "special-offer-promotion" */ './SpecialOfferStyles.vue'),
})

const show = ref(false)
const specialOfferData = ref<GD_notifications.SpecialOfferData>()
const render = document.getElementById('ml-specialOfferTeaser')
if (render && render.dataset.value) {
  try {
    specialOfferData.value = JSON.parse(render.dataset.value)
    show.value = true
  } catch (err) {
    console.error("Parsing error. Can't parse specialOfferTeaser data-value", err)
  }
}

return (_ctx: any,_cache: any) => {
  return (show.value && specialOfferData.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createBlock(_Teleport, { to: "#ml-specialOfferTeaser" }, [
          _createVNode(_unref(SpecialOfferTeaser), { "special-offer-data": specialOfferData.value }, null, 8, ["special-offer-data"]),
          _createVNode(_unref(SpecialOfferPromotionStyles))
        ]))
      ]))
    : _createCommentVNode("", true)
}
}

})