import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module-loader" }

import { defineAsyncComponent, ref } from 'vue'
import AsyncProgress from './AsyncProgress.vue'
import events from '@gd/event-bus'


export default /*@__PURE__*/_defineComponent({
  __name: 'LoyaltyWoodModal',
  setup(__props) {

const LoyaltyWood = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "loyalty-wood" */ '@gd/loyalty-wood'),
  loadingComponent: AsyncProgress,
})
const LoyaltyWoodStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "loyalty-wood" */ './LoyaltyWoodStyles.vue'),
})

const show = ref(false)
const percentage = ref(0)

events.$on('loyalty-wood', (config: { percentage: number }) => {
  percentage.value = config.percentage
  show.value = true
})

const onClose = () => {
  show.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_gd_modal = _resolveComponent("gd-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (show.value)
      ? (_openBlock(), _createBlock(_component_gd_modal, {
          key: 0,
          onCloseBG: onClose,
          onCloseESC: onClose,
          onClose: onClose,
          m: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(LoyaltyWood), {
              class: "loyalty-wood",
              percentage: percentage.value,
              onClose: onClose
            }, null, 8, ["percentage"]),
            _createVNode(_unref(LoyaltyWoodStyles))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})