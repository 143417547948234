import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "module-loader" }

import events from '@gd/event-bus'
import AsyncProgress from './AsyncProgress.vue'
import { Ref, defineAsyncComponent, ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressUpdateModal',
  setup(__props) {

const UserDataUpdate = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "address-update" */ '@gd/address-update'),
  loadingComponent: AsyncProgress,
})
const UserDataUpdateStyles = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "address-update" */ './AddressUpdateModalStyles.vue')
})

const show = ref(false)
const type: Ref<GD_notifications.AddressUpdateType | GD_notifications.MailUpdateType | null> = ref(null)

events.$on('notifications', (data: Array<any>) => {
  if (data.length > 0) {
    data.forEach((item: any) => {
      if (item.type === 'addressUpdate' || item.type === 'mailUpdate') {
        type.value = item.type
        show.value = true
      }
    })
  }
})

const onSuccess = () => {
  show.value = false
}

const onClose = () => {
  show.value = false
  if (type.value === 'addressUpdate' || type.value === 'mailUpdate') {
    fetch(`/gd/rest/me/notifications/${type.value}`, {
      method: 'delete',
      credentials: 'same-origin',
      cache: 'no-cache',
    }).catch((err) => {
      console.error(err)
    })
  }
}

return (_ctx: any,_cache: any) => {
  const _component_gd_modal = _resolveComponent("gd-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (show.value)
      ? (_openBlock(), _createBlock(_component_gd_modal, {
          key: 0,
          onCloseBG: onClose,
          onCloseESC: onClose,
          onClose: onClose
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(UserDataUpdate), {
              type: type.value,
              class: "userdata-update-modal",
              onOnSuccess: onSuccess,
              size: "m"
            }, null, 8, ["type"]),
            _createVNode(_unref(UserDataUpdateStyles))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})